import React from 'react'
// import { Link } from 'react-router-dom'
// interface Props {
//   searchText: any
//   setSearchText: any
// }
export const HeaderHome: React.FC = () => {
  return (
    <div className=''>
      <div className="recent_ mt-4">
        <h1 className='title__'>Réservez des hébergements partout à Madagascar</h1>
        <p style={{fontSize:"1.1rem"}}>Des logements indépendants, avec l'intimité, le confort et les équipements nécessaires pour un séjour inoubliable</p>
      </div>
        
    </div>
  )
}
