import React from 'react'

export default function TextMenu() {
  return (
    <div>
        <h1 className='title__ mb-4'>Bienvenue sur Haody Madagascar !</h1>
        
        <p>
            Où que vous choisissiez de <strong>voyager à Madagascar</strong>, notre plateforme vous permet de trouver le logement 
            adapté à vos besoins et à vos envies : un appartement moderne en centre-ville, une grande villa de 
            luxe vue sur mer, une maison spacieuse en périphérie, etc. Leurs points communs ? Indépendance, 
            intimité et confort.
        </p>
        <p>
            Nous disposons d’une large sélection de <strong>logements meublés et équipés</strong> dans plusieurs villes : 
            Tananarive, Antsirabe, Tamatave, Majunga, Sainte-Marie, Nosy-Be, etc.
        </p>
        <p>
            Que vous voyagiez seul, en couple ou entre amis, ici, vous trouverez des maisons, des villas ou encore des appartements disposant des commodités nécessaires pour un séjour inoubliable sur l’île rouge. 
        </p>
        <p>
        Les familles voyageant avec des enfants ne sont pas en reste. De nombreux biens pourront accueillir toute votre tribu pour des vacances mémorables.
        </p>
    </div>
  )
}
