import React from 'react';
import styles from './AboutPage.module.css';

export type AboutPageProps = {
}

const AboutPage: React.FC<AboutPageProps> = () => {
	return (
		<>
			<div className={styles.aboutPage}>
			<h1 className={styles.title}>
				À PROPOS
			</h1>

			<div className='mt-5'>
				<p>Haody Madagascar est né à la suite de nos différents voyages à Madagascar. </p>
				<p>
				En préparant nos séjours, il nous était difficile de trouver un logement répondant à nos
attentes. Mais surtout, nous ne savions pas vraiment où chercher. 
				</p>
				<p>
				Il fallait regarder des heures et des heures sur Facebook, aller sur plein de groupes différents,
scroller encore et encore. Perte de temps énorme ! Et les informations n’étaient ni fiables, ni à
jour. Parfois, malheureusement, nous faisions affaire à des interlocuteurs pas très
professionnels.
				</p>
				<p>
				En créant cette plateforme, nous avons souhaité réunir au même endroit les offres disponibles
en termes d’hébergement meublé à Madagascar, afin de simplifier la vie aux voyageurs,
vacanciers, touristes et diaspora qui visitent notre belle île.
				</p>
				<p>
				Aussi, nous sommes convaincus que pour passer un bon séjour, l’hébergement joue un rôle
primordial. C’est pour cela que nous essayons de ne proposer que des biens de qualité,
répondant à certains critères comme l’eau chaude, les sanitaires à l’intérieur, une cuisine
équipée et fonctionnelle, etc.
				</p>
				<p>
				Avec notre site de location de logements de vacances, nous espérons contribuer à une image
professionnelle des prestataires de service à Madagascar, mais surtout, inviter les voyageurs
du monde entier à créer des souvenirs inoubliables dans notre belle île.
				</p>
			</div>
		</div>
		<div className={styles.aboutPage}>
			<h1 className={styles.title}>
			NOS VALEURS
			</h1>

			<div className='mt-5'>
				<p>Il nous tient à cœur d’offrir le meilleur à ceux qui nous font confiance. Ces 5 valeurs
fondamentales guident nos actions au quotidien : </p>
				<h3 className={styles.subtitle}>EXCELLENCE</h3>
				<p>
				Chez Haody Madagascar, les détails sont importants et nous aspirons toujours au meilleur.
Cela exige de se surpasser continuellement.
				</p>
				<p>
				Nous surpasser afin d’offrir une qualité de service irréprochable, pour une satisfaction client
optimale.
				</p>
				<p>
				A travers nos décisions et dans nos interactions, nous souhaitons insuffler la culture de
l’excellence.
				</p>
				<br />
				<h3 className={styles.subtitle}>INTÉGRITÉ</h3>
				<p>
				Cela signifie tenir nos engagements et agir avec honnêteté, en toutes circonstances. 
				</p>
				<p>
				La confiance accordée par nos clients et partenaires nous est précieuse.
				</p>
				<br />
				<h3 className={styles.subtitle}>RESPECT</h3>
				<p>Nous nous engageons à traiter chaque interlocuteur avec courtoisie et bienveillance, à traiter
chaque cas avec professionnalisme et considération. </p>
				<p>Aussi, nous sommes convaincus que le respect mutuel constitue le fondement d’une
collaboration pérenne. </p>

				<br />
				<h3 className={styles.subtitle}>CURIOSITÉ</h3>
				<p>Chez Haody Madagascar, la curiosité n’est pas un vilain défaut. </p>
				<p>Nous encourageons la saine curiosité, celle qui ouvre de nouvelles perspectives, qui favorise
l’innovation et qui permet de faire la différence.</p>
				<br />
				<h3 className={styles.subtitle}>AMOUR POUR MADAGASCAR</h3>
				<p>Le dernier mais pas des moindres : l’amour pour Madagascar</p>
				<p>C’est la passion qui nous anime. La raison d’être de Haody Madagascar.</p>
			</div>
			<br />
			<br />
		</div>
		</>
	);
};

export default AboutPage;
