import React from 'react'
import "./Faq.module.css";

export default function Faq() {
  return (
    <div className='faq_container'>
      <h1 className='faq_title mt-4'>FOIRE AUX QUESTIONS</h1>
      <br />
      <br />
      <div>
        <h3 className="faq_subtitlle">Je souhaite annuler ou modifier ma réservation.</h3>
        <div className='faq_content'>
            <p>
                Pour annuler ou modifier votre réservation, merci de nous contacter par e-mail à l’adresse :
                <strong> annulation@haodymadagascar.com</strong>, en mentionnant la référence de votre réservation.
            </p>
            <p>
                Nous vous invitons également à consulter les <a href='https://conditionsgenerales.haodymadagascar.com/#annulationTitle'>conditions d’annulation et de modification</a>.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Je souhaite prolonger mon séjour.</h3>
        <div className='faq_content'>
            <p>
                Vous pouvez procéder à une nouvelle réservation sur notre site si le logement que vous souhaitez est
    disponible. Les conditions et la procédure restent inchangées.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Je souhaite arrêter la location avant la date de fin initiale.</h3>
        <div className='faq_content'>
            <p>
                Vous pouvez rendre le logement quand vous le souhaitez. Nous vous invitons à contacter votre hôte
    dont les coordonnées vous ont été transmises pour convenir de la date de sortie et rendre les clés.
            </p>
            <p>
                Toutefois, aucun remboursement des jours restants ne pourra être effectué, sauf cas exceptionnels.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Le logement n’est pas conforme à la description.</h3>
        <div className='faq_content'>
            <p>
                Si le logement diffère de manière significative de l’annonce, nous vous invitons à contacter en
    premier lieu l’hôte dont les coordonnées vous ont été transmises.
            </p>
            <p>
                Nous vous invitons également à nous en informer au plus tard 24h après votre entrée dans le
    logement, à l’adresse email : <strong>contact@haodymadagascar.com</strong>.
            </p>
            <p>
                Pour en savoir plus à ce sujet, nous vous invitons à consulter nos <a href='https://conditionsgenerales.haodymadagascar.com/'>Conditions Générales</a>.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Je n’arrive pas à joindre mon contact sur place.</h3>
        <div className='faq_content'>
            <p>
                En cas d’urgence, vous pouvez contacter nos équipes au <strong>+261 32 52 865 50</strong>.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Le propriétaire a annulé ma réservation.</h3>
        <div className='faq_content'>
            <p>
                Vous serez remboursé de la totalité de votre réservation si la somme a déjà été débitée.
            </p>
            <p>
                Il vous appartient de trouver un logement de remplacement si vous souhaitez toujours maintenir
    votre voyage.
            </p>
        </div>
      </div>

      <br />

      <div>
        <h3 className="faq_subtitlle">Je souhaite faire une réclamation.</h3>
        <div className='faq_content'>
            <p>
            Pour toute réclamation, nous vous invitons à nous contacter par email à l’adresse : <strong>contact@haodymadagascar.com</strong>
            </p>
            
        </div>
      </div>

    </div>
  )
}
