import React, { useContext, useEffect, useState } from 'react'
import { ArticlesContext } from '../../Context/AppContext'
import { ListArticle } from './Components/ListArticle/ListArticle';
import fond from '../../assets/guide/fond.png';
import im1 from '../../assets/guide/im1.jpeg';
import im2 from '../../assets/guide/im2.jpeg';
import im3 from '../../assets/guide/im3.jpeg';
import im4 from '../../assets/guide/im4.jpeg';
import im5 from '../../assets/guide/im5.jpeg';
import { Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../../services/Services';
import { Link } from 'react-router-dom';
import { ClientRouter } from '../../utils/Route/Route';
import parse from 'html-react-parser';

import imgs from '../../assets/imgs/img.png'


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y, Navigation, Pagination } from 'swiper';


export const GuideComponent: React.FC = () => {
    const articles: any = useContext(ArticlesContext);

    const [category, setCategory] = useState<any>([]);
    const [activeMenu, setMenu] = useState("");
    const [key, setKey] = useState<any>("all");

    console.log("ARTICLE", articles)

    const options = {
        replace: (domNode: any) => {
          if (domNode.attribs && domNode.attribs.class === 'remove') {
            return <></>;
          }
        },
    };


    useEffect(() => {


        const getAllLogements = async() => {
        try{
            await axios.get(`${baseUrl}categorie`).then(response => {
            
                setCategory(response.data.data.rows);
                setMenu(response.data.data.rows[0].value);

            })
        }catch{
            toast.error("Erreur de chargement des données! Veuillez réessayer SVP!");
        }
        }
        getAllLogements();

    }, []);



    let itemList = articles.filter((l: any) => {
        return (l.categorie && l.categorie.value === key);
    });

    // console.log("CATEGORY", category);

    const handleSelect = (key: any) => {
        setKey(key);
    }
    
    return (
        <div >
            <div className="row mb-4">
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        {/* <h3><Link to={`${ClientRouter.home}`}>HAODY MADAGASCAR</Link></h3> */}
                    </div>
                   <div>
                        <Link style={{color: "#000", fontWeight:'bold', fontSize:'1.2rem'}} to={`${ClientRouter.home}`}>Trouver un logement de vacances</Link>
                   </div>
                </div>
            </div>
            
            <div className="row">
            <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                autoplay
                slidesPerView={1}
                style={{height: '400px', objectFit:'cover'}}
                navigation
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 50
                    },
        
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 50
                    },
        
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50
                    },
        
                    
                  }}
                >
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}}  src={im1} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im2} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im3} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im4} alt="" className="_fiara1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img style={{objectFit:'cover', width:'200px', height: '400px'}} src={im5} alt="" className="_fiara1" />
                </SwiperSlide>
            </Swiper>
                
            </div>
            <br />
            
            <Tabs
                defaultActiveKey="all"
                id="fill-tab-example"
                className="mb-3"
                activeKey={key} 
                onSelect={(e: any) => handleSelect(e)}
            >
                <Tab eventKey="all" title="TOUS LES ARTICLES">
                    <div className='row'>
                
                        {
                            articles.length > 0 ? (
                                articles.map((article: any) => (
                                
                                    <div className="col-12 col-md-3" key={article.id}>
                                        <div className="card  mb-4" style={{height: '500px'}}>
                                            
                                            <div className="card-body p-0" style={{padding: "0px"}}>
                                            {
                                                article.files.length > 0 ? (
                                                    <div className="cardImg">
                                                        <img src={`${baseUrl}${article.files[0].path}`} alt={article.files[0].name} className="_fiara1  w-auto" />
                                                    </div> 
                                                ):(
                                                    <div className="cardImg">
                                                        <img src={imgs} alt="" className="_fiara1 w-auto" />
                                                    </div>
                                                )
                                                    
                                            }
                                            </div>


                
                                            <div className='px-4'>
                                                
                                                {parse(article.contentHtml.length > 100 ? article.contentHtml.slice(0, 100) + ' ...' : article.contentHtml, options)}
                                                
                                            </div>

                                            <div className="w-auto m-auto py-4 d-flex">
                                                <Link to={`${ClientRouter.detailGuideVoyage}/${article.id}/details`} target="_blank" className="w-100 m-auto btn btn-lg _btnShop1">
                                                    Lire la suite
                                                </Link>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                ))
                            ):(
                                <div className='card' style={{width: "100%"}}>
                                    <div className="card-body text-center">
                                        <h4>Pas de données</h4>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Tab>
                {
                    category.map((item: any) =>(
                        <Tab eventKey={item.value} key={item.id} title={item.designation.toUpperCase()}>
                                
                                
                            <ListArticle itemList={itemList}/>
                            {
                                itemList.map((data: any) => (
                                    <div key={data.id}>
                                        <h3>{data.category}</h3>
                                        <>{data.contenu}</>
                                    </div>
                                ))
                            }
                        </Tab>
                    ))
                }
            </Tabs>
            
        </div>
    )
}
