import React, { useContext } from 'react'
import { ArticleContext } from '../../Context/AppContext'
import parse from 'html-react-parser';
import { baseUrl } from '../../services/Services';
import fond from '../../assets/images/fond.png';

export const DetailGuidVComponent = () => {
    const article: any = useContext(ArticleContext)
    
    return (
        <div>
             <div className="row mb-4">
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        {/* <h3 className='d-none'><a href="#">HAODY MADAGASCAR</a></h3> */}
                    </div>
                   <div>
                    <a style={{color: "#000", fontWeight:'bold', fontSize:'1.2rem'}} href="#">Trouver un logement de vacances</a>
                   </div>
                </div>
            </div>
            <div className="row">
                <div className="card" style={{width: "100%", height: "350px"}}>
                    <div className='articleHead' >
                        {/* <label className='textFond' htmlFor="">TEXT</label> */}
                        <img className='imgFond' src={fond} alt="" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mt-4">
                        
                        <div className="row">
                            
                            <div className="col-12 col-md-4"></div>
                            <div className="col-12 col-md-4">
                                {
                                    article.files && article.files.map((data: any) => (
                                        <img style={{width: "100%", margin: 'auto'}} src={`${baseUrl}${data.path}`} alt={data.name} />
                                    ))
                                }
                            </div>
                            <div className="col-12 col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-8">
                    <div className="">
                        {/* <div className="card-content">

                            <h1 style={{textTransform: "uppercase", fontWeight: "600", textAlign: "center"}}>{article.categorie && article.categorie.designation}</h1>

                        </div> */}
                        <div className="card-body">
                            {/* <h4>{article.title}</h4> */}
                            <p style={{textAlign:"justify"}}>
                                {parse(`${article.contentHtml}`)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-2"></div>
            </div>
        </div>
    )
}
