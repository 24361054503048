import { 
    PaymentElement,
    useElements,
    PaymentRequestButtonElement,
    useStripe, 
    CardElement,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { AiFillBackward, AiOutlineShoppingCart } from "react-icons/ai";
import { toast } from "react-toastify";
import { Toast } from "react-toastify/dist/components";
import { textSpanIsEmpty } from "typescript";
import { baseUrl } from "../../../services/Services";
import { ClientRouter } from "../../../utils/Route/Route";
import ReactLoading from 'react-loading';


const CLIENT_SECRET = "sk_test_51LMz7LHHPOnTqKjqEA12JVyqhJxbEkGymLULjUJagtTLtkF1O2Tya0ikpsAAsNFhGDUCvso8glCzUgOV3kBTWkON00uNjQ0iVo"


const options = {
    // passing the client secret obtained from the server
    clientSecret: CLIENT_SECRET,
  };

interface Props {
    valeur: any,
    devise: any,
    amount: any,
    dateEnd: any,
    startDate: any,
    logement: any,
    currency: any,
    reference: any,
    show: any,
    setShow: any,
    nom: any,
    setNom: any,
    prenom: any,
    setPrenom: any,
    email: any,
    setEmail: any
    adresse: any,
    setAdresse: any
    phone: any
    setPhone: any
    desc: any,
    setDesc: any,
    nbrVoyageur: any,
    setNbrVoyageur: any,
    handleShow: any,
    handleClose: any
}

const CARD_OPTIONS: any = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#fff",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}

export const CheckoutForm: React.FC<Props> = ({
    valeur,
    devise,
    amount,
    dateEnd,
    startDate,
    logement,
    currency,
    reference,
    show,
    setShow,
    nom,
    setNom,
    prenom,
    setPrenom,
    email,
    setEmail,
    adresse,
    setAdresse,
    phone,
    setPhone,
    desc,
    setDesc,
    nbrVoyageur,
    setNbrVoyageur,
    handleShow,
    handleClose
}) => {
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [success, setSuccess ] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);

    

    const currencyUnit: any = localStorage.getItem("currency");
    const deviseName: any = localStorage.getItem("devise");

    const [ref, setRef] = useState<any>("");

    // const loyer = amount;


    let formatter: any;
    
    if (devise === "Ariary") {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: "MGA",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    } else {
        formatter = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencyUnit,
        });
    }

    const loyer: any = amount / valeur;
    const fixedNum = Math.round(loyer*100)/100;


    const [paymentRequest, setPaymentRequest] = useState(null);

    const payementOK = async (e: any) => {
        e.preventDefault();
        setIsProcessing(true);

        let amountPai = fixedNum * 100;

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement, CardNumberElement)
        })

        if(!error) {
            
            try {
                const {id} = paymentMethod

                const response = await axios.post(`${baseUrl}detail/paiement`, {
                    reservationLogement:{

                        logement:{
                
                            id: logement,
                            reference: reference
                        },
                        dateDebut: startDate,
                        dateFin: dateEnd,
                        nombreVoyageur: nbrVoyageur
                    },
                    paiement: {
                        montant: currency !== "MGA" ? amountPai.toFixed(0) : loyer,
                        description: desc,
                        token: 'test mlj',
                        payment_method: id, 
                        curency: currency,
                    },
                    user:{

                        email: email,
                        nom: nom,
                        prenom: prenom,
                        adresse: adresse,
                        password: "FRFDJKDKJD",
                        telephone: phone
                    }
                });

                
                if(response.data.success) {
                    console.log("Successful payment")
                    setSuccess(true);
                    setShow(false)
                    setAdresse("")
                    setNbrVoyageur("")
                    setNom("")
                    setPrenom("")
                    setEmail("")
                    setPhone("");
                    setIsProcessing(false);
                }
                

            } catch (error: any) {
                toast.error(error.response.data && error.response.data.message);
                handleClose();
                // window.location.reload();
                setIsProcessing(false);
            }
        } else {
            toast.error(error.message);
            setIsProcessing(false);
        }
    }

    const cancelPayment = () => {
        handleClose();
        setIsProcessing(false);
    }

    return (
        <>
        {!success ? 
            <div>
                <form>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Nom</label>
                            <input value={nom} onChange={(e) => setNom(e.target.value)} type="text" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>
                        
                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Prénom(s)</label>
                            <input value={prenom} onChange={(e) => setPrenom(e.target.value)} type="text" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>

                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Pays de résidence</label>
                            <input value={adresse} onChange={(e) => setAdresse(e.target.value)} type="text" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>

                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Adresse email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>
                        

                    </div>

                    <div className="row mb-3">
                        

                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Téléphone</label>
                            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>

                        <div className="col-12 col-md-6">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Nombre de voyageurs (+3ans)</label>
                            <input value={nbrVoyageur} onChange={(e) => setNbrVoyageur(e.target.value)} type="email" className="form-control input-lg" id="exampleFormControlInput1" />
                        </div>

                    </div>
                    {/* <div className="row mb-3">
                        
                        <div className="col-12">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Commentaire</label>
                            <textarea className="form-control" value={desc} onChange={(e) => setDesc(e.target.value)} name="" id="" cols={30} rows={5}></textarea>
                        </div>
                        

                    </div> */}
                    <hr />
                    <div className="row mb-3">
                        
                            <div className="col-12">
                                <fieldset>
                                    <legend>Information de paiement:</legend>
                                    
                                    <CardElement className="form-control" />
                                </fieldset>
                            {/* <label htmlFor="exampleFormControlInput1" className="form-label">Paiement</label> */}
                                {/* <PaymentElement/> */}
                        </div>
                        

                    </div>
                    
                    
                </form>
                
            </div>
            :
            <div>
                <div className="alert alert-success">
                    <h2 className="text-center">Félicitations !</h2>
                    <h2 className="text-center">Votre réservation a été effectuée avec succès!  </h2>
                </div>
            </div> 
        }

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={cancelPayment}
        >
            <Modal.Header closeButton>
                <Modal.Title>Récapitulatif</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="card">
                        <div className="card-body">
                            <h4 style={{fontSize: "1.2rem"}}>Client: <b style={{float: "right", fontSize: "1.2rem"}}>{nom} {prenom}</b></h4>
                            <h4 style={{fontSize: "1.2rem"}}>Numéro de téléphone: <b style={{float: "right", fontSize: "1.2rem"}}>{phone}</b></h4>
                            <h4 style={{fontSize: "1.2rem"}}>Pays de résidence: <b style={{float: "right", fontSize: "1.2rem"}}>{adresse}</b></h4>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h4 style={{fontSize: "1.2rem"}}>Séjour: <b style={{float: "right", fontSize: "1.2rem"}}>{startDate} au {dateEnd}</b></h4>
                            <hr />
                            <h4 style={{fontSize: "1.2rem", textTransform: "uppercase"}}>Total à payer:  
                            <b style={{float: "right", fontSize: "1.2rem"}}>{formatter.format(amount / valeur)}</b>
                            </h4>
                        </div>
                    </div>
                </div>
            </Modal.Body>
                    <Modal.Footer>
                            {/* disabled={isProcessing} */}

                    <button type="button" onClick={() => cancelPayment()} className="btn btn-danger">Annuler</button>
                    <button disabled={isProcessing} type="button"  onClick={(e) => payementOK(e)} className="btn btn-primary d-flex">
                        {
                            isProcessing ? (
                                <>
                                    <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0} />
                                    Chargement...
                                </>
                            ) : (
                                <>
                                    Valider
                                </>
                            )
                        }
                        
                    </button>
                
                </Modal.Footer>
        </Modal>
            
        </>
    )
}
