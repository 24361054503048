import React from 'react'
import ConditionGenComponent from '../../Components/ConditionGenComponent/ConditionGenComponent'

export default function ConditionGeneral() {
  return (
    <div>
      <ConditionGenComponent/>
    </div>
  )
}
