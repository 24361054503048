import React from 'react'

import { ContactComponents } from '../../Components/ContactComponents/ContactComponents';
export const ContactPage: React.FC = () => {
  return (
    <div className="_container">
      
        <ContactComponents/>
      
    </div>
  )
}
