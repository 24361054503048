import React, { useContext } from 'react'
import { LogementContext } from '../../Context/AppContext'
import { CardLogement } from './Components/CardLogement/CardLogement'
import {HeaderHome} from './Components/HeaderHome/HeaderHome'
import TextMenu from './Components/TextMenu/TextMenu'
interface Props {
  devise: any,
  valeur: any
}
export const DashboardComponent: React.FC<Props> = ({devise, valeur}) => {

  const items = useContext(LogementContext);


  // console.log("LOGEM", items);

  

  return (
    <div >
        <HeaderHome/>
        
        <CardLogement devise={devise} valeur={valeur} />

        <div className="row mt-4">
          <div className="col-12 col-md-8 m-auto text-center">
            <TextMenu/>
          </div>
          
        </div>

    </div>
  )
}
