import React from 'react'
import { GuideComponent } from '../../Components/GuideComponent/GuideComponent'
import { ArticlesContext } from '../../Context/AppContext';
import ReactLoading from 'react-loading';
interface Props {
    articles: any;
    loading: any;
}
export const GuideVoyage: React.FC<Props> = ({articles, loading}) => {
    // const [articles, setArticles] = useState<any>([]);
    // const [loading, setLoading] = useState<boolean | any>(false);

    // useEffect(() => {

    //     setLoading(true);

    //     const getAllLogements = async() => {
    //     try{
    //         await axios.get(`${baseUrl}article`).then(response => {
            
    //         setArticles(response.data.data.rows);
    //         setLoading(false);

    //         })
    //     }catch{
    //         setLoading(false);
    //         // console.log("Tsy mety");
    //     }
    //     }
    //     getAllLogements();

    // }, []);

    return (
        <div className="_container">
        {
            loading ? (
                <ReactLoading type="bars" color="#373942" height={'20%'} width={'5%'} className="loading" delay={0}/>
            ):(
                <ArticlesContext.Provider value={articles}>
                    <GuideComponent/>
                </ArticlesContext.Provider>
            )
        }
        </div>
    )
}
